exports.components = {
  "component---src-pages-2024-thai-night-js": () => import("./../../../src/pages/2024-thai-night.js" /* webpackChunkName: "component---src-pages-2024-thai-night-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-community-mdx": () => import("./../../../src/pages/community.mdx" /* webpackChunkName: "component---src-pages-community-mdx" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-past-js": () => import("./../../../src/pages/events/past.js" /* webpackChunkName: "component---src-pages-events-past-js" */),
  "component---src-pages-exec-boards-mdx": () => import("./../../../src/pages/exec-boards.mdx" /* webpackChunkName: "component---src-pages-exec-boards-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-mdx": () => import("./../../../src/pages/join.mdx" /* webpackChunkName: "component---src-pages-join-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-thai-night-js": () => import("./../../../src/pages/thai-night.js" /* webpackChunkName: "component---src-pages-thai-night-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-qrcode-js": () => import("./../../../src/pages/tools/qrcode.js" /* webpackChunkName: "component---src-pages-tools-qrcode-js" */),
  "component---src-pages-tools-thai-keyboard-js": () => import("./../../../src/pages/tools/thai-keyboard.js" /* webpackChunkName: "component---src-pages-tools-thai-keyboard-js" */),
  "component---src-templates-event-js-content-file-path-events-2006-01-01-thai-night-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2006-01-01-thai-night.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2006-01-01-thai-night-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2007-02-23-love-letter-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2007-02-23-love-letter.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2007-02-23-love-letter-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2016-10-20-king-rama-the-ix-remembrance-event-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2016-10-20-king-rama-the-ix-remembrance-event.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2016-10-20-king-rama-the-ix-remembrance-event-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2016-10-27-scary-comedy-movie-night-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2016-10-27-scary-comedy-movie-night.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2016-10-27-scary-comedy-movie-night-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2019-04-06-thai-night-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2019-04-06-thai-night.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2019-04-06-thai-night-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2020-11-14-loy-krathong-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2020-11-14-loy-krathong.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2020-11-14-loy-krathong-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2022-04-10-international-street-fair-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2022-04-10-international-street-fair.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2022-04-10-international-street-fair-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2022-10-13-thai-cave-rescure-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2022-10-13-thai-cave-rescure.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2022-10-13-thai-cave-rescure-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-03-01-global-education-fair-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-03-01-global-education-fair.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-03-01-global-education-fair-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-03-06-global-education-fair-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-03-06-global-education-fair.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-03-06-global-education-fair-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-09-02-planning-meeting-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-09-02-planning-meeting.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-09-02-planning-meeting-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-09-06-travelling-to-thailand-info-session-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-09-06-travelling-to-thailand-info-session.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-09-06-travelling-to-thailand-info-session-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-09-20-trivia-night-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-09-20-trivia-night.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-09-20-trivia-night-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-10-06-cis-potluck-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-10-06-cis-potluck.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-10-06-cis-potluck-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-10-18-movie-night-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-10-18-movie-night.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-10-18-movie-night-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-11-01-craft-night-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-11-01-craft-night.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-11-01-craft-night-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-11-15-loy-krathong-part-1-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-11-15-loy-krathong-part-1.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-11-15-loy-krathong-part-1-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2023-11-29-loy-krathong-part-2-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2023-11-29-loy-krathong-part-2.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2023-11-29-loy-krathong-part-2-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2024-03-21-global-education-fair-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2024-03-21-global-education-fair.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2024-03-21-global-education-fair-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2024-04-06-international-street-fair-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2024-04-06-international-street-fair.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2024-04-06-international-street-fair-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2024-04-13-thai-music-workshop-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2024-04-13-thai-music-workshop.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2024-04-13-thai-music-workshop-mdx" */),
  "component---src-templates-event-js-content-file-path-events-2024-04-14-thai-night-mdx": () => import("./../../../src/templates/event.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/events/2024-04-14-thai-night.mdx" /* webpackChunkName: "component---src-templates-event-js-content-file-path-events-2024-04-14-thai-night-mdx" */),
  "component---src-templates-post-js-content-file-path-posts-2024-03-26-hello-world-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/posts/2024-03-26-hello-world.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-posts-2024-03-26-hello-world-mdx" */),
  "component---src-templates-post-js-content-file-path-posts-2024-03-26-logos-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/runner/work/itstsaou.github.io/itstsaou.github.io/posts/2024-03-26-logos.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-posts-2024-03-26-logos-mdx" */)
}

